import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Tab, TextField } from "@mui/material"
import { ServerList, updatedServerList } from "../ServerList"
import { useEffect, useState } from "react"
import { GeralField } from "../field";
import {setAnyFirstLetterToUpper } from "../../utils/string";
import { getJustNumbers, numberToCurrencyString } from "../Inputs";
import { getNegotiationEnum } from "../../utils/negotiationenum";
import { GeralSelectField } from "../field";
import { MyDatePicker } from "../Inputs";
import { LoadingButton, TabContext, TabList, TabPanel, Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from "@mui/lab";
import { Fragment } from "react";
import { sendServerRequest } from "../../utils/sendreq";
import { updateSnackStatus } from "../Snack";
import { useRef } from "react";
import { WhatsAppLink, getNumberWithCountryDDD } from "../../utils/wanumber";
import { MyDateTimePicker } from "../Inputs";
const csvHeader=[
   {label:'Nome Do Cliente',id:'client_name'},
   {label:'Nome Do Vendedor',id:'dealer_name'},
   {label:'Telefone Do Cliente',id:'client_phone'},
   {label:'Status Da Negociação',id:'negotiation_status'},
   {label:'Tipo de Negócio',id:'negotiation_type'},
   {id:'looking_for',label:'Procurando Por'},
   {id:'capture_source',label:'Fonte De Captura'},
   {id:'desired_value',label:'Valor Desejado'},
   {id:'value',label:'Fechamento (R$)',func:(v)=>numberToCurrencyString(v?v/100:0,"R$",2)},
   {id:'start_negotiation_date',label:'Início Da Negociação',func:formatDate},
   {id:'next_negotiation_date',label:'Próxima Negociação',func:(s)=>formatDate(s,true)},
   {id:'end_negotiation_date',label:'Fim da Negociação',func:formatDate},
   {id:'observations',label:'Observações',func:(obs)=>{
       if(!obs||!Array.isArray(obs)||obs.length<1)
          return "Sem observações";
       let ret='\"';
       for(let o of obs){
          ret+=`${formatDate(o.createdAt)} - ${o.data}\n`
       }
       ret=ret.slice(0,ret.length-1);
       ret+=`\"`;
       return ret;
   }},
];
function convertToCSVData(header,items,currentHeader){
    let headerText="";
    let useHeader=[];
    for(let h of header){
       let jump=false;
       for(let oldh of currentHeader){
          if(oldh.id==h.id){
              if(oldh.hidden){
                jump=true;
              }
              break;
          }
       } 
       if(jump)
          continue;
       useHeader.push(h); 
       headerText+=`${h.label};`
    }
    headerText=headerText.slice(0,headerText.length-1);
    let body="";
    for(let i of items){
       for(let h of useHeader){
           body+=`${h.func?h.func(i[h.id]):i[h.id]};`;
       }
       body=body.slice(0,body.length-1);
       body+="\n";
    }
    return `${headerText}\n${body}`;
 }

function Loading(){
     return (<div style={{textAlign:'center'}}>
        <CircularProgress/>
     </div>)
}
function showData(item,head){
    if(!item[head.id])
       return <p>{`Sem Data`}</p>
    return formatDate(item[head.id],head.id=='next_negotiation_date');
}

export function NegotiationOrSchedule(){
    const [tabsIndex,setTabsIndex]=useState(0);
    return (
        <TabContext value={tabsIndex}>
                    <TabList  onChange={(a,i)=>{setTabsIndex(i)}} centered>
                        <Tab label={'Negócios'} />
                        <Tab label={'Agenda'}/>
                    </TabList>
                  <TabPanel value={0}>  
                       <Negotiation/>
                 </TabPanel>
                 <TabPanel value={1}>
                     <Schedule/>
                 </TabPanel>
        </TabContext>
    )
}

function Schedule(){
    const isAdm=window.userData.permissionType==='adm';
    const [userId,setUserId]=useState(window.userData.id);
    const [users,setUsers]=useState(false);
    const [items,setItems]=useState(false);
    const usersRef=useRef();
    useEffect(()=>{
      if(isAdm)  
         updateUsers();
    },[]);
    useEffect(()=>{
       if(!items)
         updateTimeLine();
    },[items]);
    useEffect(()=>{
       setItems(false);
    },[userId]);
    return (
        <div>
          {isAdm?<div style={{maxWidth:"250px",margin:'auto'}}>  
         {!users?<Loading/>:<GeralSelectField onChange={updateUserId} label={'Usuário'} items={users||[]}/>}
          </div>:null}
          {!items?<Loading/>:<TimeLineItems items={items||[]}/>}
        </div>
    )
    function updateUserId(key){
        const sUser=usersRef.current[key];
        if(!sUser)
           return;
        if(sUser.id==userId){
            return;
        }
        setUserId(sUser.id);
    }
    function updateUsers(){
        sendServerRequest('/server/adm/userslist').then(r=>{
              const selectUsers=[];
              const usersData={};
              for(let u of r.data.data.users){
                  const key=`${u.first_name} - ${u.email}`;
                  selectUsers.push(key);
                  usersData[key]=u;
              }
              setUsers(selectUsers);
              usersRef.current=usersData;
        }).catch(e=>{

        })
    }
    function updateTimeLine(){
        sendServerRequest(`/server/negotiation/nextNegotiation/${userId}`).then(e=>{
            setItems(e.data.data.negotiations);
        }).catch(e=>{
            updateSnackStatus({
                open:true,
                message:'Error ao carregar a agenda, atualize a página',
                severity:'error',
            })
        })
    }
    function TimeLineItems({items}){
        if(!items||items.length<1){
            return <div style={{textAlign:'center'}}><p>Sem agendamenetos futuros.</p></div>
        }
        return (
            <Timeline position="alternate">
              {items.map((t,i)=>{
                  return (<TimelineItem >
                      <TimelineOppositeContent color="text.secondary">
                         {formatDate(t.next_negotiation_date,true)}
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineDot/>
                        {i!==items.length-1?<TimelineConnector/>:null}
                      </TimelineSeparator>
                      <TimelineContent>
                         {`${t.client_name} - ${getNumberWithCountryDDD(t.client_phone)}`}
                      </TimelineContent>
                  </TimelineItem>)
              })}
            </Timeline>
        )
    }
}
export function Negotiation(){
    const header=[
        {id:'client_name',label:"Nome",isRequired:true},
        {id:'client_phone',label:'Telefone',showFunc:(i)=>{return <WhatsAppLink number={getNumberWithCountryDDD(i['client_phone'])}/>}},
        {id:'dealer_name',label:'Vendedor'},
        {id:'dealer_email',label:'Vendedor Email',hidden:true},
        {id:'negotiation_status',label:'Status'},
        {id:'value',label:'Fechamento (R$)',showFunc:(v)=>numberToCurrencyString(v.value?v.value/100:0,"R$",2)},
        {label:'Tipo',id:'negotiation_type'},
        {id:'looking_for',label:'Procurando Por',hidden:false},
        {id:'capture_source',label:'Captura',hidden:true,},
        {id:'start_negotiation_date',label:'Início',hidden:true,disableSearch:true,showFunc:showData},
        {id:'end_negotiation_date',label:'Fim',hidden:true,disableSearch:true,showFunc:showData},
        {id:'next_negotiation_date',label:'Próxima Negociação',hidden:true,disableSearch:true,showFunc:showData},
        {id:'desired_value',label:'Valor Desejado',hidden:true},
        {id:'id',label:'Ver Mais',isRequired:true,disableSearch:true,disableSort:true,showFunc:(item)=>{return (<Button onClick={()=>{setSelectedNegotiation(item)}} variant="contained">Ver Mais</Button>)}}
      ];
    const [openReg,setOpenReg]=useState(false);
    const [oldData,setOldData]=useState(false);
    const [selectedNegotiation,setSelectedNegotiation]=useState(false);
    useEffect(()=>{
        getNegotiationEnum();
        return ()=>{};
    },[])
    useEffect(()=>{
       if(selectedNegotiation){
          setOpenReg(true);
       }
    },[selectedNegotiation]);
    useEffect(()=>{
        if(!openReg){
            setSelectedNegotiation(false);
        }
    },[openReg]);
    return (
    <div style={{textAlign:'center',marginTop:'40px'}}>
        <Button variant="contained" onClick={()=>{setOpenReg(true)}}>Criar Negócio</Button>
        <Divider sx={{marginTop:"10px",marginBottom:'10px'}}/>
        <ServerList onUpdated={setOldData} gridId={'negotiation'} csvConversor={(items,cheader)=>convertToCSVData(csvHeader,items,cheader)} processData={(e)=>e.data.list} header={header} url={'/server/negotiation/list'} />
        <RegisterOrEditNegotiation oldData={oldData} open={openReg} data={selectedNegotiation} closeHandle={()=>{setOpenReg(false)}}/>
    </div>)
}

function UpdateUserNegotiation({data}){
    const [users,setUsers]=useState([]);
    const [items,setItems]=useState([]);
    const [loading,setLoading]=useState(false);
    const [selectedUser,setSelectedUser]=useState(false);
    const [defaultUser,setDefaultUser]=useState(false);
    const [disabledEdit,setDisabledEdit]=useState(true);
    const [loadingEdit,setLoadingEdit]=useState(false);
    useEffect(()=>{
        if(loadingEdit&&selectedUser){
            let uid=0;
            for(let u of users){
                if(u.email==selectedUser){
                    uid=u.id;
                    break;
                }
            }
            sendServerRequest('/server/negotiation/changeUser',JSON.stringify({
                id:data.id,
                userId:uid,
            })).then(e=>{
                
                updateSnackStatus({
                    open:true,
                    message:"Usuário atualizado com sucesso!",
                    severity:'success'
                  });
                  setLoadingEdit(false);
                  data.dealer_id=uid;
                  setDisabledEdit(true);
                  updatedServerList();
            }).catch(e=>{
                updateSnackStatus({
                    open:true,
                    message:"Erro ao mudar usuário, atualize a página.",
                    severity:'error'
                  })
            })
        }
    },[loadingEdit])
    useEffect(()=>{
        if(users.length==0&&!loading){
              setLoading(true);
        }
        return ()=>{} 
    });
    useEffect(()=>{
       if(users.length>0){
             console.log(users);
             let lbs=[];
             console.log(data);
             for(let u of users){
                 lbs.push(u.email);
                 if(u.id==data.dealer_id){
                    setDefaultUser(u.email);
                 }
             }
             setItems(lbs);
       }
    },[users]);
    useEffect(()=>{
       if(!selectedUser)
          return;
       let uId=0;
       for(let u of users){
          if(u.email==selectedUser){
            uId=u.id;
            break;
          }
       }
       if(uId==data.dealer_id){
          setDisabledEdit(true);
       }else{
           setDisabledEdit(false);
       } 
    },[selectedUser])
    useEffect(()=>{
       if(items.length>0&&loading){
          setLoading(false);
       }
    },[items]);
    useEffect(()=>{
       if(loading&&users.length==0){
            sendServerRequest('/server/adm/userslist').then(e=>{
                setUsers(e.data.data.users);
            }).catch(e=>{
                 updateSnackStatus({
                    message:'Erro ao carregar usuário, atualize a página',
                    severity:'error',
                    open:true,
                 })
            })
       }
    },[loading])
    return (
       <div style={{textAlign:'center'}}>
            {loading?<CircularProgress/>:<div>
                <GeralSelectField defaultValue={defaultUser} onChange={e=>{
                    setSelectedUser(e);
                }} items={items} label={'Usuário'}/>
                <LoadingButton loading={loadingEdit} onClick={()=>setLoadingEdit(true)} fullWidth variant="contained" disabled={disabledEdit}>Mudar Usuário</LoadingButton>
            </div>}
       </div>  
    )
}
function RegisterOrEditNegotiation({open,data,closeHandle,oldData}){
      const [negotiationEnum,setNegoatiationEnum]=useState({});
      const [defaultData,setDefaultData]=useState({});
      const [cData,setCData]=useState({});
      const [canSave,setCanSave]=useState(false);
      const [fastEdit,setFastEdit]=useState(true);
      const [loading,setLoading]=useState(false);
      const [editable,setEditable]=useState(false);
      const [tabsIndex,setTabsIndex]=useState(0);
      const [observations,setObservations]=useState([]);
      const [deleting,setDeleting]=useState(false);
      const lastData=useRef("Start");
      useEffect(()=>{
          getNegotiationEnum().then((d)=>{
              setNegoatiationEnum(d);
          }).catch(e=>{});
          updateDefault();
          return ()=>{};
      },[]);
      useEffect(()=>{
         if(deleting&&!loading){
              setLoading(true);
         }
         if(!deleting&&loading){
            setLoading(false);
         }
      },[deleting])
      useEffect(()=>{
         if(lastData.current!==data){
            updateDefault();
         }
      });
      useEffect(()=>{
         setCData(defaultData);
      },[defaultData]);
      useEffect(()=>{
          checkCanSave();
      },[cData])

      useEffect(()=>{
         if(loading&&deleting){
              sendServerRequest('/server/negotiation/delete',JSON.stringify({
                  id:data.id,
              })).then(e=>{
                updateSnackStatus({
                    open:true,
                    message:"Deletado com sucesso",
                    severity:'success'
                  });
                  updatedServerList();
                  closeHandle();
              }).catch(e=>{
                updateSnackStatus({
                    open:true,
                    message:"Erro ao deletar",
                    severity:'error'
                  });
                  setDeleting(false);
              })
              return;
         }
         if(loading){
            let sendData={};
          let url='/server/negotiation/edit';
          if(data.id){
               for(let key in cData){
                   if(data[key]===cData[key])
                      continue;
                   sendData[key]=cData[key];
               }
               sendData.id=data.id;
               if(sendData.negotiation_status=='Fechado'){
                  if(!sendData.end_negotiation_date){
                      sendData.end_negotiation_date=new Date();
                  }
               }
          }else{
            sendData=cData;
            url='/server/negotiation/create';
          }
         if(sendData.value){
            sendData.value=getJustNumbers(sendData.value);
         }
         let sendRequest=true;
         if(sendData.negotiation_status=='Negociando'){
            const nextneg=sendData.next_negotiation_date||new Date(data.next_negotiation_date);
            if(nextneg<new Date()){
                  updateSnackStatus({
                    open:true,
                    message:"A próxima negociação deve ser uma data futura",
                    severity:'info'
                  })
                  //setCanSave(false);
                  setLoading(false);
                  return;
            }
         }
         if(sendData.client_phone){
              const nphone=getNumberWithCountryDDD(sendData.client_phone);
              let have=null;
              if(oldData)
              for(let o of oldData){
                   if(nphone==getNumberWithCountryDDD(o.client_phone)){
                      have=o;
                      break;
                   }
              }
              if(have){
                sendRequest=window.confirm(`Ja existe um cliente com esse número cadastrado no nome ${have.client_name}, deseja continuar?`);
              }

         }
         if(sendData.next_negotiation_date){
             const nextnegtime=sendData.next_negotiation_date.getTime();

             let have=false;
             if(oldData)
               for(let o of oldData){
                  if(o.dealer_email!==window.userData?.email)
                     continue;
                  const olddate=(new Date(o.next_negotiation_date)).getTime();
                  if(!olddate) 
                     continue;
                  if(nextnegtime>olddate-3600000&&nextnegtime<olddate+3600000){
                      have=o;
                      break;
                  }  
               }
             if(have){
                sendRequest=window.confirm(`Você ja tem um horário agendado proximo a esse, com o cliente ${have.client_name} as ${formatDate(have.next_negotiation_date,true)}, deseja continuar?`)
             }   
         }
         if(!sendRequest){
           setLoading(false);
           return;
         }
         sendServerRequest(url,JSON.stringify(sendData)).then(e=>{
             updateSnackStatus({
                open:true,
                severity:'success',
                message:'Atualizado com sucesso!',
             });
             setLoading(false);
             setCanSave(false);
             updatedServerList();
             if(!data){
                updateDefault();
                closeHandle();
             }else{
                if(cData.observation){
                    const nObs=[].concat(observations);
                    nObs.push({
                        data:cData.observation,
                        createdAt:new Date()
                    });
                    setObservations(nObs);
                    changeValue('observation','');
                }
             }
         }).catch(e=>{
            updateSnackStatus({
                open:true,
                severity:'error',
                message:'Erro, atualize a página.',
             });
             setLoading(false);
             setCanSave(false);
         });
        
         }
      },[loading])
      return(
          <Dialog open={open}>
              <DialogTitle>{data?`${data.client_name} - ${data.client_phone}`:'Criar Negociação'}</DialogTitle>
              <DialogContent value={tabsIndex}>
                 {data?<p>{`Vendedor: ${data.dealer_name} - ${data.dealer_email}`}</p>:null}
                 <TabContext value={tabsIndex}>
                    <TabList  onChange={(a,i)=>{setTabsIndex(i)}} centered>
                        <Tab label={'Editor'} />
                        <Tab disabled={!data} label={'Observações'}/>
                        <Tab disabled={!data||window.userData?.permissionType!=='adm'} label={'Configuração'}/>
                    </TabList>
                  <TabPanel value={0}>  
                 <div>
                     
                     {data?<div style={{textAlign:'center'}}><Button variant="contained" onClick={()=>setFastEdit(!fastEdit)}>{fastEdit?'Ver Tudo':'Simplificar'}</Button></div>:null}
                     {data&&fastEdit?null:<Fragment>
                        <GeralField editable={editable} onChange={(v)=>changeValue('client_name',v)} fullWidth name={'client_name'} label='Nome Completo' defaultValue={defaultData.client_name} filters={[setAnyFirstLetterToUpper]}/>
                        <GeralField editable={editable} onChange={(v)=>changeValue('client_phone',v)} fullWidth name={'client_phone'} type='phone' label='Telefone' defaultValue={defaultData.client_phone} filters={[(s)=>getJustNumbers(s,13)]}/>
                        <GeralSelectField editable={editable} onChange={(v)=>changeValue('negotiation_type',v)} items={negotiationEnum.negotiation_type||[]} defaultValue={defaultData.negotiation_type} label="Tipo De Negócio"/>
                        <GeralSelectField editable={editable} onChange={(v)=>changeValue('looking_for',v)} items={negotiationEnum.looking_for||[]} defaultValue={defaultData.looking_for} label="Procurando Por"/>
                        <GeralSelectField editable={editable} onChange={(v)=>changeValue('desired_value',v)} items={negotiationEnum.desired_value||[]} defaultValue={defaultData.desired_value} label="Valor Desejado"/>
                        <GeralSelectField editable={editable} onChange={(v)=>changeValue('capture_source',v)} items={negotiationEnum.capture_source||[]} defaultValue={defaultData.capture_source} label="Fonte de captura"/>
                        <MyDatePicker editable={editable} onChange={(v)=>changeValue('start_negotiation_date',v)} name="start_negotiation" label="Inicio da Negociação" defaultValue={defaultData.start_negotiation_date} maxDate={new Date()}/>
                       
                     </Fragment>}
                     <Fragment>
                        <GeralSelectField editable={editable} onChange={(v)=>changeValue('negotiation_status',v)} items={negotiationEnum.negotiation_status||[]} defaultValue={defaultData.negotiation_status} label="Status Do Negócio"/>
                        {cData.negotiation_status&&cData.negotiation_status=='Fechado'?<GeralField editable={editable} name='value' label='Valor de fechamento (R$)' defaultValue={numberToCurrencyString(defaultData.value?defaultData.value/100:0,'R$',2)} fullWidth onChange={(v)=>changeValue('value',v)} filters={[(v)=>numberToCurrencyString(v,'R$',2)]}/>:null}
                        {cData.negotiation_status&&cData.negotiation_status!=='Negociando'?<MyDatePicker editable={true} onChange={(v)=>changeValue('end_negotiation_date',v)} name="end_negotiation" label="Fim da Negociação" defaultValue={defaultData.end_negotiation_date}/>:null}
                        <MyDateTimePicker editable={editable} onChange={(v)=>changeValue('next_negotiation_date',v)} name="next_negotiation" label="Próxima Negociação" defaultValue={defaultData.next_negotiation_date} minDate={new Date((new Date()).getTime()-50000)}/>
                        <TextField multiline onChange={(v)=>changeValue('observation',v.target.value)} value={cData.observation} fullWidth name={'observation'} label={data?'Adicionar Observação':'Observação'} defaultValue={defaultData.observation}/>
                     </Fragment>
                    
                 </div>
                 </TabPanel>
                 <TabPanel value={1}>
                    <Observations observations={observations}/>
                 </TabPanel>
                 <TabPanel value={2}>
                    <UpdateUserNegotiation data={data}/>
                    <div style={{textAlign:'center',marginTop:'15px'}}><Button disabled={loading} onClick={deleteNegotiation} color='error' variant="contained">DELETAR</Button></div>
                 </TabPanel>
                 </TabContext>
              </DialogContent>
              <DialogActions>
                 <LoadingButton loading={loading} size="large" disabled={!canSave||tabsIndex!=0} onClick={()=>save()} variant="contained">Salvar</LoadingButton>
                 <Button  disabled={loading} size='small' color='info' variant="contained" onClick={closeHandle}>Sair</Button>
              </DialogActions>
          </Dialog>
      )
      function deleteNegotiation(){
           let conf=window.confirm("Você tem certeza que deseja deletar essa negociação?");
           if(!conf)
             return;
           setDeleting(true);
           
      }
      function checkCanSave(){
         if(data&&cData.observation){
             setCanSave(true);
             return;
         }
          
         const requiredData=[
            'client_name',
            'client_phone',
            'negotiation_type',
            'negotiation_status',
            'looking_for',
            'desired_value',
            'capture_source',
            'start_negotiation_date',
            'next_negotiation_date',
         ];
         let tCanSave=true;
         for(let key of requiredData){
            if(!cData[key]){
               tCanSave=false;
               break;
            }
         }
         if(data.id&&tCanSave){
            if(!data.observation&&(data.end_negotiation_date===cData.end_negotiation_date)){
                tCanSave=false;
                for(let key of requiredData){
                    if(cData[key]!==data[key]){
                        tCanSave=true;
                        break;
                    }
                }
           }
           if(cData.value!==data.value)
                   tCanSave=true;
         }
         setCanSave(tCanSave);
      }
      function changeValue(key,value){
         let obj={};
         Object.assign(obj,cData);
         obj[key]=value;
         setCData(obj);
      }
      function save(){
          setLoading(true);
      }
      function updateDefault(){
        setFastEdit(true);
        setTabsIndex(0);
        if(data){
            let setData={};
            Object.assign(setData,data);
            setDefaultData(data);
            setEditable(true);
            setObservations(data.observations||[]);
            
        }else{
            setObservations([]);
            setEditable(false);
            setDefaultData({
                negotiation_type:'Venda',
                negotiation_status:'Negociando',
                desired_value:'Em Análise',
                start_negotiation_date:new Date(),
                next_negotiation_date:new Date(),
                end_negotiation_date:new Date(),
                observation:"Iniciando negociação com o cliente."
            });
            
        }
        lastData.current=data;
      }
      function Observations({observations}){
           if(observations.length<1){
            return <p>Sem Observações</p>
           }
           return (
              <div>
                  <ol>
                    {observations.map(o=>{
                        return <li><ObservationItem i={o}/></li>
                    })}
                  </ol>
              </div>
           )
           function ObservationItem({i}){
                return (
                    <dl>
                        <dt style={{marginBottom:'1px'}}>{i.data?.trim()}</dt>
                        <dt style={{fontSize:'13px',color:'#0705ff80',marginTop:'0px',float:'right'}}>{formatDate(i.createdAt)}</dt>
                    </dl>
                )
           }
      }
}


function formatDate(val,showHours){
    if(!val)
      return "";
    const d=new Date(val);
    const year=d.getFullYear();
    const month=d.getMonth()+1;
    const day=d.getDate();
    let ret=`${addZero(day)}/${addZero(month)}/${year}`;
    if(showHours){
        const hour=d.getHours();
        const min=d.getMinutes();
        ret+=` ${addZero(hour)}:${addZero(min)}`;
    }
    return ret;
    function addZero(number){
      if(number>9)
        return number.toString();
      return `0${number}`;
    }   
}
