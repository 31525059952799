import { sendServerRequest } from "./utils/sendreq";
import { updateSnackStatus } from "./MyComponents/Snack";
export const CURRENT_VERSION=22102;

export function formatVersion(n){
    let temp=n;
    let last=temp%100;
    temp-=last;
    temp/=100;
    let middle=temp%100;
    temp-=middle;
    temp/=100;
    return `${temp}.${middle}.${last}`;
}
export async function getServerVersion(){
    try{
        if(!window.serverVersion||(
            (new Date()).getTime()>window.serverVersion.updatedAt.getTime()+600000
        )){
            const r=await sendServerRequest(`/server/server/version`);
            window.serverVersion={
                data:r.data,
                updatedAt:new Date()
            }
        }
        return window.serverVersion.data;
    }catch(e){
        return {};
    }    
}
export async function checkVersion(forceUpdate){
   try{
      const version=await getServerVersion();
      if(CURRENT_VERSION<version.data.frontEnd){
         if(forceUpdate){
            window.location.reload()
            return;
         }
         if(!window.serverVersion.snackSended){
            updateSnackStatus({
                open:{
                    action:{
                        label:'Atualizar',
                        onClick:()=>window.location.reload()
                    }
                },
                autoHideDuration:20000,
                message:'Nova versão disponível, clique para atualizar.',
            });
            window.serverVersion.snackSended=true;
        }
      }
   }catch(e){
      return {};
   }
}

